const tailSelect = require('tail.select.js')

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  connect() {
    activateTailSelects(this.element)
  }

}

//////////////////////////////
// Helpers ///////////////////
//////////////////////////////

function activateTailSelects(context) {
  tailSelect(context.querySelectorAll('.simple-select'))
  tailSelect(context.querySelectorAll('.searchable-select'), {
    search: true,
    searchConfig: 'any' })
}